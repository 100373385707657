<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="
      currentUserData
        ? 'Пользователь ' + currentUserData.item.displayName
        : 'Новый пользователь'
    "
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <!-- !checkRoleAccess_(['Dis.monitoring.admin']) -->
      <b-form-checkbox
        v-if="
          !currentUserData &&
            checkFeatureAccess({
              name: 'Поиск. Авторизация по ЕСИА',
              url: '/users'
            })
        "
        v-model="isEsia"
        class="mb-2"
        size="sm"
        @change="onEsiaCheckboxChange"
      >
        Авторизация через ЕСИА
      </b-form-checkbox>
      <template v-if="isEsia">
        <div class="d-flex">
          <b-form-group
            label="Фамилия"
            class="w-50 mr-2"
          >
            <b-form-input
              :value="userData.lastName"
              type="text"
              disabled
              trim
            />
          </b-form-group>
          <b-form-group
            label="Имя"
            class="w-50"
          >
            <b-form-input
              :value="userData.firstName"
              type="text"
              disabled
              trim
            />
          </b-form-group>
        </div>

        <div class="d-flex mb-3">
          <b-form-group
            label="Отчество"
            class="w-50 mr-2"
          >
            <b-form-input
              :value="userData.middleName"
              type="text"
              disabled
              trim
            />
          </b-form-group>
          <b-form-group
            label="Снилс"
            class="w-50"
            :disabled="!!currentUserData"
          >
            <b-form-input
              v-model="$v.userData.snils.$model"
              v-maska="'###-###-### ##'"
              type="text"
              :placeholder="'Введите Снилс'"
              :state="validateState('snils')"
              trim
            />
            <b-form-invalid-feedback>
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="d-flex mb-3">
          <b-form-group
            label="Номер телефона"
            class="w-50 mr-2"
          >
            <b-form-input
              v-model="$v.userData.phoneNumber.$model"
              type="text"
              :placeholder="'Введите номер телефона'"
              :state="validateState('phoneNumber')"
              trim
            />
            <b-form-invalid-feedback>
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="d-flex w-50">
            <b-form-group
              label="Дата рождения"
              class="w-50 mr-2"
            >
              <b-form-input
                :value="userData.birthday"
                type="text"
                disabled
                trim
              />
            </b-form-group>

            <b-form-group
              label="Пол"
              class="w-50"
            >
              <b-form-input
                :value="userData.middleName"
                type="text"
                disabled
                trim
              />
            </b-form-group>
          </div>
        </div>

        <div class="d-flex mb-3">
          <b-form-group
            label="Email"
            class="w-50 mr-2"
          >
            <b-form-input
              :value="userData.email"
              type="text"
              disabled
              trim
            />
          </b-form-group>

          <b-form-group
            label="ЕСИА ID"
            class="w-50"
          >
            <b-form-input
              :value="userData.esiaUserId"
              type="text"
              disabled
              trim
            />
          </b-form-group>
        </div>
      </template>

      <template v-else>
        <b-form-group label="Фамилия">
          <b-form-input
            v-model="$v.userData.lastName.$model"
            type="text"
            :placeholder="'Введите Фамилию'"
            :state="validateState('lastName')"
            trim
          />
          <b-form-invalid-feedback>
            Данное поле обязательно*
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Имя">
          <b-form-input
            v-model="$v.userData.firstName.$model"
            type="text"
            :placeholder="'Введите Имя'"
            :state="validateState('firstName')"
            trim
          />
          <b-form-invalid-feedback>
            Данное поле обязательно*
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Логин">
          <b-form-input
            v-model="$v.userData.login.$model"
            type="text"
            placeholder="Введите логин"
            :state="validateState('login')"
            trim
          />
          <b-form-invalid-feedback>
            Данное поле обязательно*
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Пароль">
          <b-input-group class="bg-white border-0 rounded">
            <b-form-input
              v-model="$v.userData.password.$model"
              :state="validateState('password')"
              :type="passwordInputType"
              placeholder="Введите пароль"
              autocomplete="new-password"
              trim
            />
            <b-button
              variant="outline"
              class="rounded border-0 passwordBtn"
              @click="passwordDisplay"
            >
              <b-icon
                v-if="passwordShow"
                icon="eye"
              />
              <b-icon
                v-if="!passwordShow"
                icon="eye-slash"
              />
            </b-button>
            <b-form-invalid-feedback>
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>

        <b-form-group label="Email">
          <b-form-input
            v-model="userData.email"
            :type="'email'"
            placeholder="Введите email"
            trim
          />
        </b-form-group>
      </template>

      <b-form-group
        label="Роль"
        :disabled="disabledFields.roles"
      >
        <b-form-select
          v-model="$v.userData.roleId.$model"
          :state="validateState('roleId')"
          :options="filteredRoles"
          size="sm"
          value-field="id"
          text-field="title"
          @change="onRoleChange"
        >
          <template #first>
            <b-form-select-option
              :value="null"
              disabled
            >
              Выберите роль
            </b-form-select-option>
          </template>
        </b-form-select>
        <b-form-invalid-feedback>
          Данное поле обязательно*
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        v-if="isMedicalWorker"
        label="Доверенность"
      >
        <b-form-input
          v-model="$v.userData.powerOfAttorney.$model"
          :state="checkValidations ? !$v.userData.powerOfAttorney.$error : null"
          placeholder="Доверенность"
          trim
        />
        <b-form-invalid-feedback>
          Данное поле обязательно*
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- регионы -->

      <template v-if="showRegionsBlock">
        <b-form-group label="Прикрепленный регион">
          <b-table-simple bordered small>
            <b-tbody>
              <b-tr v-if="userData.regionAdmin.id">
                <b-td class="w-100">
                  {{ userData.regionAdmin.name }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <!--          v-if="abilityAddAndRemoveClinic && !isAdminDisMonitoring"-->
          <div
            class="d-flex"
          >
            <b-button
              :variant="regionTableShow ? 'secondary' : 'primary'"
              class="ml-auto"
              size="sm"
              style="width: 200px"
              @click="toggleRegionTableShow"
            >
              {{ regionTableShow ? 'Скрыть' : userData.regionAdmin.id ? 'Изменить регион' : 'Добавить регион' }}
            </b-button>
          </div>
        </b-form-group>

        <template v-if="regionTableShow">
          <b-form-input
            v-model="regionSearchString"
            type="text"
            debounce="500"
            placeholder="Поиск региона по названию"
            size="sm"
            trim
            class="mb-2"
          />

          <div
            v-if="regionSearchString && filteredRegionsList.length"
            style="max-height: 200px; overflow-y: auto;"
          >
            <b-table-simple
              small
              bordered
              class="mb-0"
            >
              <b-tbody>
                <b-tr
                  v-for="region in filteredRegionsList"
                  :key="region.okatoCode"
                >
                  <b-td class="w-100">
                    {{ region.name }}
                  </b-td>
                  <b-td>
                    <b-button
                      v-if="region.okatoCode === userData.regionAdminOkato"
                      variant="primary"
                      class="ml-auto"
                      size="sm"
                    >
                      <b-icon icon="check" />
                    </b-button>
                    <b-button
                      v-else
                      variant="success"
                      class="ml-auto"
                      size="sm"
                      @click="onRegionAdd(region.okatoCode)"
                    >
                      <b-icon icon="plus" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>

          <small
            v-if="regionSearchString && !filteredRegionsList.length"
            class="ml-2"
          >
            Регион не найден
          </small>

          <small
            v-if="!regionSearchString"
            class="ml-2"
          >
            Введите название региона
          </small>
        </template>
        <div
          v-if="!userData.regionAdminOkato"
          class="invalid-feedback custom"
        >
          Необходимо выбрать регион*
        </div>
      </template>
      <template v-if="showClinicsBlock && !isUserMedicalWorker && !isGovStaffDevice">
        <b-form-group label="Прикрепленные клиники">
          <b-table-simple
            v-if="userData.clinicIds.length"
            bordered
            small
          >
            <b-tbody>
              <b-tr
                v-for="clinic in userData.clinicIds"
                :key="clinic.id"
              >
                <b-td class="w-100">
                  {{ clinic.name }}
                </b-td>
                <b-td v-if="isAdminDisMonitoring || isTmkAdmin || isSuperAdmin || isAdminRegion">
                  <b-button
                    variant="danger"
                    class="ml-auto"
                    size="sm"
                    @click="onClinicRemove(clinic.id)"
                  >
                    <b-icon icon="trash" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

          <div
            v-if="abilityAddAndRemoveClinic && !isAdminDisMonitoring"
            class="d-flex"
          >
            <b-button
              :variant="clinicTableShow ? 'secondary' : 'primary'"
              class="ml-auto"
              size="sm"
              style="width: 200px"
              @click="toggleClinicTableShow"
            >
              {{ clinicTableShow ? 'Скрыть' : 'Добавить клинику' }}
            </b-button>
          </div>
        </b-form-group>

        <template v-if="clinicTableShow || isUserMedicalWorker">
          <b-form-input
            v-model="clinicSearchString"
            type="text"
            debounce="500"
            placeholder="Поиск клиники по названию"
            size="sm"
            trim
            class="mb-2"
          />

          <div
            v-if="clinicSearchString && filteredClinicsNameList.length"
            style="max-height: 200px; overflow-y: auto;"
          >
            <b-table-simple
              small
              bordered
              class="mb-0"
            >
              <b-tbody>
                <b-tr
                  v-for="clinic in filteredClinicsNameList"
                  :key="clinic.id"
                >
                  <b-td class="w-100">
                    {{ clinic.name }}
                  </b-td>
                  <b-td>
                    <b-button
                      variant="success"
                      class="ml-auto"
                      size="sm"
                      @click="onClinicAdd(clinic.id)"
                    >
                      <b-icon icon="plus" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>

          <small
            v-if="clinicSearchString && !filteredClinicsNameList.length"
            class="ml-2"
          >
            Клиника не найдена
          </small>

          <small
            v-if="!clinicSearchString"
            class="ml-2"
          >
            Введите название клиники
          </small>
        </template>
        <div
          v-if="!userData.clinicIds.length"
          class="invalid-feedback custom"
        >
          Необходимо выбрать клинику*
        </div>
      </template>
      <template v-if="showClinicsBlock && (isUserMedicalWorker || isGovStaffDevice)">
        <b-form-group label="Клиника">
          <b-form-select
            v-model="selectedClinic"
            :state="validateState('clinicIds')"
            :options="adminClinics"
            value-field="id"
            text-field="title"
            size="sm"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                Выберите клинику
              </b-form-select-option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback>
            Данное поле обязательно*
          </b-form-invalid-feedback>
        </b-form-group>
      </template>

      <hr>

      <template v-if="!isEsia && !isMedicalWorker">
        <b-form-checkbox
          v-model="userData.showPassports"
          class="mt-3"
          size="sm"
        >
          <span class="text-secondary">
            Выгружать паспорта
          </span>
        </b-form-checkbox>

        <b-form-checkbox
          v-model="userData.canAuthorize"
          size="sm"
        >
          <span class="text-secondary">
            Разрешить авторизоваться за пользователя
          </span>
        </b-form-checkbox>
      </template>
    </template>
     <!-- если есть связанный доктор -->
     <div v-if="userData.roleId === 'e36bd62c-6859-41cf-9954-a53a5d9df6df' && currentUserData?.item.doctor.id">
      <b-form-checkbox
        v-model="islinkedDoctorId"
        size="sm"
      >
        <span class="text-secondary">
          Создать связанный профиль Врача
        </span>
      </b-form-checkbox>
      <b-form-group v-if="islinkedDoctorId" label="Связанный профиль">
        <b-form-input
          v-model="linkedDoctorValue"
          :type="'text'"
          disabled
          placeholder="Введите ID доктора"
          trim
        />
      </b-form-group>
    </div>
    <div v-if="userData.roleId === 'e36bd62c-6859-41cf-9954-a53a5d9df6df' && !currentUserData?.item.doctor.id && isEsia">
      <b-form-checkbox
        v-model="islinkedDoctorId"
        size="sm"
      >
        <span class="text-secondary">
          Создать связанный профиль Врача
        </span>
      </b-form-checkbox>
      <!-- {{ findSnilsDoctor }} -->
      <b-form-group v-if="islinkedDoctorId" class="mt-2" label="Врачи">
        <b-form-select
          v-model="selectedDoctor"
          :options="findSnilsDoctor"
          value-field="id"
          text-field="title"
          :disabled="$v.userData.snils.$invalid || selectedSpecialization"
          size="sm"
        >
          <template #first>
            <b-form-select-option
              :value="null"
              disabled
            >
              Выберите Доктора
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group v-if="islinkedDoctorId" class="mt-2" label="Специализация">
        <b-form-select
          v-model="selectedSpecialization"
          :options="findSpecializstion"
          value-field="id"
          text-field="title"
          :disabled="isSpecializationDisabled"
          size="sm"
        >
          <template #first>
            <b-form-select-option
              :value="null"
              disabled
            >
              Выберите Специализацию
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
    </div>
    <b-form-checkbox
      v-if="isClinicAdministrator"
      v-model="userData.canReferralToDuty"
      size="sm"
    >
      <span class="text-secondary">
        Направление на дежурную консультацию
      </span>
    </b-form-checkbox>
    <template #modal-footer>
      <b-button
        v-if="currentUserData"
        variant="danger"
        class="float-right ml-2"
        :disabled="isLoading"
        @click="onClose"
      >
        Отмена
      </b-button>

      <b-button
        variant="primary"
        class="float-right"
        :disabled="isLoading"
        @click="onSaveUser"
      >
        {{ currentUserData ? 'Изменить' : 'Сохранить пользователя' }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
// import {
//   // formatISO,
//   format,
// } from 'date-fns';
import Preloader from '@/components/Preloader';
import { mapState } from 'vuex';
import { mixinRoles } from '@/mixins';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { showMessage } from '@/helpers/utils';
import { ROLE } from '@/helpers/consts';

export default {
  name: 'UserViewModal',
  components: {
    Preloader,
  },
  mixins: [validationMixin, mixinRoles],
  props: {
    userId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    currentUserData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      checkValidations: false,
      passwordShow: true,
      clinicTableShow: false,
      regionTableShow: false,
      clinicSearchString: '',
      regionSearchString: '',
      isLoading: false,
      adminClinics: [],
      passwordInputType: 'password',
      isEsia: false,
      userData: {
        snils: null,
        esiaUserId: null,
        birthday: null,
        sex: null,
        phoneNumber: null,
        email: null,
        firstName: null,
        lastName: null,
        login: null,
        middleName: null,
        password: null,
        showPassports: false,
        canReferralToDuty: false,
        roleId: null,
        clinicIds: [],
        regionAdminOkato: null,
        regionAdmin: { id: null, name: null },
        canAuthorize: false,
        powerOfAttorney: null,
        // создание связанного пользователя
        linkedDoctorId: null,
        createDoctor: null,
      },
      roleList: [],
      clinicsNameList: [],
      regionsList: [],
      tableFields: [
        { key: 'name', label: '' },
        { key: 'actions', label: '' },
      ],
      // создание связанного пользователя
      islinkedDoctorId: false,
      findSnilsDoctor: {},
      findSpecializstion: {},
    };
  },
  validations() {
    return {
      userData: {
        snils: {
          required: this.isEsia ? required : () => true,
          isValid: this.isEsia ? (val) => val?.length === 14 : () => true,
        },
        phoneNumber: {
          required: this.isEsia ? required : () => true,
        },
        firstName: {
          required: !this.isEsia ? required : () => true,
        },
        lastName: {
          required: !this.isEsia ? required : () => true,
        },
        login: {
          required: !this.isEsia ? required : () => true,
        },
        password: {
          required:
            !this.isEsia && !this.currentUserData ? required : () => true,
        },
        powerOfAttorney: {
          required: this.isMedicalWorker ? required : () => true,
        },
        clinicIds: {
          required: requiredIf(
            () => this.showClinicsBlock || this.isUserMedicalWorker,
          ),
        },
        regionAdminOkato: {
          required: requiredIf(
            () => this.showClinicsBlock && !this.isGovExtraAdmin && !this.isGovStaffDevice,
          ),
        },
        roleId: { required },
      },
    };
  },
  computed: {
    ...mapState({
      doctors: (state) => state.Doctor.doctors, // Получение списка врачей
    }),
    selectedClinic: {
      get() {
        return this.userData.clinicIds[0]?.id;
      },
      set(clinicId) {
        this.userData.clinicIds = [clinicId];
      },
    },
    isSpecializationDisabled() {
      return !!this.selectedDoctor;
    },
    // Выбранный врач
    selectedDoctor: {
      get() {
        return this.userData.linkedDoctorId ? String(this.userData.linkedDoctorId) : null;
      },
      set(value) {
        // Если выбран врач, обнуляем специализацию
        if (value) {
          this.userData.linkedDoctorId = Number(value);
          this.userData.createDoctor = null; // Обнуляем выбранную специализацию
        } else {
          this.userData.linkedDoctorId = null;
        }
      },
    },
    // Выбранная специализация
    selectedSpecialization: {
      get() {
        return this.userData.createDoctor ? this.userData.createDoctor.specializationId : null;
      },
      set(value) {
        // Если выбрана специализация, обнуляем врача
        if (value) {
          this.userData.createDoctor = {
            specializationId: value,
            experience: null,
            isForAdults: true,
            isForChildren: true,
          };
          this.userData.linkedDoctorId = null; // Обнуляем выбранного врача
        } else {
          this.userData.createDoctor = null;
        }
      },
    },
    showClinicsBlock() {
      return (
        this.isClinicAdministrator
        || this.isDisMonitoring
        || this.isDisMonitoringTeam
        || this.isUserMedicalWorker
        || this.isMethodicCenter
        || this.isTmkAdmin
        || this.isGovStaffDevice
        || this.isGovExtraAdmin
      );
    },
    showRegionsBlock() {
      return (
        this.isRegionAdministrator
      );
    },
    isMethodicCenter() {
      return this.userRole?.name === 'methodic.center';
    },
    adminClinicList() {
      return this.user.clinicAdminIds ?? [];
    },
    isUserMedicalWorker() {
      return this.userRole?.name === ROLE.DM_MEDICAL_WORKER;
    },
    isGovStaffDevice() {
      return this.userRole?.name === ROLE.GOV_STAFF_DEVICE;
    },
    isGovExtraAdmin() {
      return this.userRole?.name === ROLE.GOV_EXTRA_ADMIN;
    },
    isGovAdminRegion() {
      return this.userRole?.name === ROLE.GOV_ADMIN_REGION;
    },
    isGovAdminStaff() {
      return this.userRole?.name === ROLE.GOV_ADMIN_STAFF;
    },
    actualRole() {
      if (this.roleList.length) {
        return this.roleList.find((item) => item.id === this.userData.roleId);
      }

      return {};
    },
    onlyClinicAdministratorInRoles() {
      // this.checkRoleAccess_('Operator.gostelemed')
      return this.checkFeatureAccess({
        name: 'В фильтре только роль ClinicAdministrator',
        url: '/users',
      });
    },
    // isMethodicCenter() {
    //   // this.checkRoleAccess_(['Dis.monitoring.admin']);
    //   return this.checkFeatureAccess({ name: 'Видеть только роль medical.worker.dis.monitoring', url: '/users' });
    // },

    viewRolesOnlyForMonitoringAdmin() {
      return this.checkFeatureAccess({ name: 'Видеть только роль medical.worker.dis.monitoring', url: '/users' });
    },
    onlyGovAdminSoft() {
      return this.checkFeatureAccess({ name: 'Видеть только роль gov.admin.soft', url: '/users' });
    },
    onlyGovAdminRegion() {
      return this.checkFeatureAccess({ name: 'Видеть только роль gov.admin.region', url: '/users' });
    },
    onlyGovExtraAdminMoFull() {
      return this.checkFeatureAccess({ name: 'Видеть только роль gov.extra.admin.mo.full', url: '/users' });
    },
    onlyGovStaffDevice() {
      return this.checkFeatureAccess({ name: 'Видеть только роль gov.staff.device', url: '/users' });
    },
    abilityAddAndRemoveClinic() {
      // this.checkRoleAccess_(['Dis.monitoring.admin']);
      return this.checkFeatureAccess({
        name: 'Возможность добавлять и удалять прикрепленные клиники',
        url: '/users',
      });
    },
    isMedicalWorker() {
      try {
        return this.actualRole.name === ROLE.DM_MEDICAL_WORKER;
      } catch (e) {
        return false;
      }
    },
    filteredRoles() {
      try {
        const roleList = this.roleList.map((role) => {
          if (!role.title) {
            role.title = role.name;
          }
          return role;
        });
        if (this.viewRolesOnlyForMonitoringAdmin) {
          return roleList.filter((item) => ['medical.worker.dis.monitoring', 'methodic.center'].includes(
            item.name,
          ));
        }
        if (this.onlyGovAdminSoft) {
          return roleList.filter((item) => item.name === 'gov.admin.soft');
        }
        if (this.onlyGovAdminRegion) {
          return roleList.filter((item) => item.name === 'gov.admin.region');
        }
        if (this.onlyGovExtraAdminMoFull) {
          return roleList.filter((item) => item.name === 'gov.extra.admin.mo.full');
        }
        if (this.onlyGovStaffDevice) {
          return roleList.filter((item) => item.name === 'gov.staff.device');
        }
        return roleList;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    user() {
      return this.$store.state.Auth.user;
    },
    userRole() {
      return this.roleList.find((r) => r.id === this.userData.roleId);
    },
    isRegionAdministrator() {
      return this.userRole?.name === 'gov.admin.region';
    },
    isClinicAdministrator() {
      return this.userRole?.name === 'ClinicAdministrator';
    },
    isTmkAdmin() {
      return this.userRole?.name === 'tmk.admin';
    },
    isAdminRegion() {
      return this.userRole.name === 'gov.extra.admin.mo.full';
    },
    isDisMonitoring() {
      return (
        this.userRole?.name === 'Dis.monitoring.admin'
        || this.userRole?.name === 'admin.Dis.monitoring'
      );
    },
    isSuperAdmin() {
      return this.user.roles.some((r) => r.name === 'Administrator');
    },
    isAdminDisMonitoring() {
      // оставлю, в сохранении непонятная логика
      return this.checkRoleAccess([
        'Dis.monitoring.admin',
        'admin.Dis.monitoring',
      ]);
    },
    isDisMonitoringTeam() {
      return this.userRole?.name === 'Dis.monitoring.team';
    },
    filteredClinicsNameList() {
      if (!this.clinicSearchString) return [];
      return this.clinicsNameList.filter((c) => {
        if (
          !c?.name
            ?.toLowerCase()
            ?.includes(this.clinicSearchString.toLowerCase())
        ) return false; // search by clinic name from clinicSearchString

        return !this.userData.clinicIds.find((cc) => cc.id === c.id); // return false if clinic added to userdata
      });
    },
    filteredRegionsList() {
      if (!this.regionSearchString) {
        return [];
      }
      return this.regionsList.filter((r) => r?.name?.toLowerCase()?.includes(this.regionSearchString.toLowerCase()));
    },
    disabledFields() {
      return {
        roles: this.onlyClinicAdministratorInRoles || false,
      };
    },
    linkedDoctorValue: {
      get() {
        if (this.islinkedDoctorId && this.currentUserData.item?.doctor?.id) {
          // Если доктор привязан и есть его ID, отображаем его name
          return this.currentUserData.item.doctor.name;
        }
        // Если доктора нет, используем введённый ID или null
        return this.userData.linkedDoctorId || null;
      },
      set(value) {
        this.userData.linkedDoctorId = this.islinkedDoctorId ? value : null;
      },
    },
  },
  watch: {
    isUserMedicalWorker(bool) {
      if (bool) {
        this.updateClinics();
      }
    },
    isGovStaffDevice(bool) {
      if (bool) {
        this.updateClinics();
      }
    },
    // Следим за изменением флага islinkedDoctorId
    islinkedDoctorId(newVal) {
      if (!newVal) {
        // Если чекбокс выключен, очищаем поле linkedDoctorId
        this.userData.linkedDoctorId = null;
      } else if (this.currentUserData.item?.doctor?.id) {
        // Если чекбокс включен и есть ID доктора, присваиваем его
        this.userData.linkedDoctorId = this.currentUserData.item.doctor.id;
      }
    },
    'userData.snils': function (newSnils) {
      if (newSnils && newSnils.length === 14) {
        this.fetchDoctors(newSnils);
      }
    },
  },
  async created() {
    try {
      this.isLoading = true;
      this.roleList = await this.$store.dispatch(this.$types.ROLES_FETCH);
      this.clinicsNameList = await this.$store.dispatch(
        this.$types.CLINICS_NAME_FETCH,
      );
      this.regionsList = await this.$store.dispatch(
        this.$types.REGIONS_FETCH,
      );
      this.fetchSpecializations('');
      if (this.viewRolesOnlyForMonitoringAdmin) {
        const actualRole = this.roleList.find(
          (item) => item.name === 'medical.worker.dis.monitoring',
        );
        if (actualRole) {
          this.userData.roleId = actualRole.id;
        }

        // if (!this.currentUserData && !this.isUserMedicalWorker) {
        //   this.userData.clinicIds = this.user.clinicAdminIds.map((item) => ({ id: item }));
        // }

        this.userData.clinicIds = this.clinicsNameList.filter((cl) => this.user.clinicAdminIds.includes(cl.id));
        this.userData.regionAdminOkato = this.user.regionAdminOkato;
      }

      if (this.currentUserData) {
        const userData = await this.$store.dispatch(
          this.$types.GET_USER_BY_ID,
          this.currentUserData.item.id,
        );

        this.isEsia = !!userData.esiaUserId;

        this.userData.showPassports = userData.showPassports;
        this.userData.canReferralToDuty = userData.referralToDuty;
        this.userData.canAuthorize = userData.canAuthorize;
        this.userData.clinicIds = userData.clinicIds; // .map((i) => i.id);
        this.userData.regionAdminOkato = userData.regionAdminOkato;
        this.userData.regionAdmin = userData.regionAdmin;
        this.userData.powerOfAttorney = userData.powerOfAttorney;

        this.userData.firstName = this.currentUserData.item.firstName;
        this.userData.lastName = this.currentUserData.item.lastName;
        this.userData.login = this.currentUserData.item.login;
        this.userData.email = this.currentUserData.item.email;
        this.userData.roleId = this.currentUserData.item.roleId;
        // создание связанного пользователя
        // Если есть доктор, устанавливаем флаг и ID
        if (this.currentUserData.item?.doctor?.id) {
          this.islinkedDoctorId = true;
          this.userData.linkedDoctorId = this.currentUserData.item.doctor.id;
        } else {
          this.islinkedDoctorId = false;
          this.userData.linkedDoctorId = null;
        }
      }
      if (!this.currentUserData && this.onlyClinicAdministratorInRoles) {
        const clinicAdminRole = this.roleList.find(
          (role) => role.name === 'ClinicAdministrator',
        );
        this.userData.roleId = clinicAdminRole.id;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async fetchDoctors(item) {
      try {
        const doctors = await this.$store.dispatch(this.$types.DOCTORS_FETCH, {
          skip: 0,
          take: 10, // Количество врачей для получения
          snils: item, // запрос
          save: true, // Сохранить полученные данные в состоянии Vuex
          isDeleted: false, // Указать, что нужны только активные врачи
        });
        this.findSnilsDoctor = this.createListForSelect(doctors, 'fullName');
      } catch (error) {
        console.error('Ошибка в получении доктора:', error);
      }
    },
    createListForSelect(data, titleField) {
      const selectList = data.map((item) => {
        if (!item.title) {
          item.title = item[titleField]; // Доступ к значению через строку
        }
        return item;
      });
      return selectList;
    },
    async fetchSpecializations(query) {
      const data = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, query);
      this.findSpecializstion = this.createListForSelect(data, 'name');
      return data;
    },
    async updateClinics() {
      const clinics = await this.$store.dispatch(
        this.$types.ADMIN_CLINICS_FETCH,
      );
      const isClinic = clinics.findIndex(
        ({ id }) => id === this.selectedClinic,
      );
      if (isClinic === -1) {
        const clinic = this.userData.clinicIds.find(
          ({ id }) => id === this.selectedClinic,
        );

        if (clinic) {
          clinics.push({
            id: clinic.id,
            title: clinic.name,
          });
        }
      }
      // если надо будет добавить нет в select clinics
      // clinics.unshift({
      //   id: null,
      //   title: 'Нет',
      // });
      this.adminClinics = clinics;
    },
    onEsiaCheckboxChange() {
      this.userData.login = null;
      this.userData.password = null;

      this.userData.showPassports = false;
      this.userData.canAuthorize = false;

      this.userData.firstName = null;
      this.userData.lastName = null;
      this.userData.email = null;

      this.userData.snils = null;
      this.userData.phoneNumber = null;
    },
    onRoleChange() {
      this.userData.canReferralToDuty = false;
    },
    toggleClinicTableShow() {
      this.clinicTableShow = !this.clinicTableShow;
      this.clinicSearchString = '';
    },
    toggleRegionTableShow() {
      this.regionTableShow = !this.regionTableShow;
      this.regionSearchString = '';
    },
    onClinicAdd(clinicId) {
      if (this.userData.clinicIds.find((c) => c.id === clinicId)) return; // return if clinic already in array

      const clinic = this.clinicsNameList.find((c) => c.id === clinicId);
      if (clinic) {
        this.userData.clinicIds.push(clinic);
      }
    },
    onRegionAdd(okatoCode) {
      if (this.userData.regionAdminOkato === okatoCode) return; // return if region already selected

      const region = this.regionsList.find((r) => r.okatoCode === okatoCode);
      if (region) {
        this.userData.regionAdminOkato = okatoCode;
        this.userData.regionAdmin = region;
      }
    },
    onClinicSet(id) {
      this.userData.clinicIds = [
        {
          id,
        },
      ];
    },
    onClinicRemove(clinicId) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'UserClinicDeleteModal',
        props: {
          callback: () => {
            this.userData.clinicIds = this.userData.clinicIds.filter(
              (c) => c.id !== clinicId,
            );
          },
        },
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.userData[name];
      return $dirty ? !$error : null;
    },
    passwordDisplay() {
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password';
      this.passwordShow = !this.passwordShow;
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onSaveUser() {
      try {
        this.$v.$touch();
        this.checkValidations = true;

        if (this.$v.$error) {
          return;
        }

        // !!! здесь трогать не стал
        if (
          !(
            this.isClinicAdministrator
            || this.isDisMonitoring
            || this.isDisMonitoringTeam
            || this.isMethodicCenter
            || this.isTmkAdmin
          )
          && !this.isAdminDisMonitoring
          && !this.isGovExtraAdmin
          && !this.isGovStaffDevice
        ) {
          this.userData.clinicIds = [];
        }

        let method = this.currentUserData ? 'USER_EDIT' : 'USER_CREATE';
        const clinicIds = this.userData.clinicIds.map((i) => i.id || i).filter((i) => i);
        if (!this.currentUserData && this.isEsia) method = 'USER_CREATE_FOR_ESIA';
        await this.$store.dispatch(this.$types[method], {
          ...this.userData,
          id: this.currentUserData ? this.currentUserData.item.id : null,
          clinicIds,
          regionAdminOkato: this.userData.regionAdminOkato,
        });
        this.$store.commit(this.$types.TOGGLE_FETCH_USERS_TRIGGER); // сделает тогл триггера и обновит юзеров
        this.onClose();
      } catch (e) {
        console.error(e);
        showMessage({
          type: 'error',
          title: 'Ошибка',
          message: `Что-то пошло не так. ${e}`,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.passwordBtn:focus {
  box-shadow: none;
}
.invalid-feedback.custom {
  display: block;
}
</style>
